import * as React from "react";
import Layout from "../components/layout";

const NotFoundPage = () => {
  return (
    <Layout>
        <div>Oops! 404 Not Found</div>
    </Layout>
  );
};

export default NotFoundPage

export const Head = () => <title>Not found</title>
